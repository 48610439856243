'use client';

/* eslint-disable @next/next/no-img-element */
import React from 'react';
import { usePathname } from 'next/navigation';

import HeaderLinks from '../HeaderLinks';
import { ROUTES } from '@/constants/routes/default';
import Link from 'next/link';
import { cn } from '@/helpers/common';

function HeaderKD() {
  const pathname = usePathname();

  return (
    <header
      className={cn('flex md:px-10 max-md:px-4 min-h-[140px] bg-bittersweet relative', {
        'header-background': pathname !== ROUTES.home,
      })}
    >
      <div className="max-w-[1040px] w-full h-full flex justify-between items-center m-auto flex-wrap gap-4 z-10">
        <Link href={ROUTES.home}>
          <img
            width="100"
            height="100"
            src="https://assets.kochdavisjobs.com/kd/logo-150.png"
            alt="Logo"
          />
        </Link>
        <HeaderLinks
          listHeaderCn="px-3.5 py-2 text-white3"
          dropdownClassNames={{
            listCn:
              'max-w-[200px] right-0 bg-secondary py-2 rounded-lg border border-main-border-color',
            elementCn: 'text-sm text-main-text-color h-9 px-5 flex items-center hover:bg-tundora',
            rootCn: '',
            iconCn: 'h-9 w-9 rounded-lg hover:bg-tundora [&>svg>path]:!fill-white',
            activeIconCn: 'bg-tundora',
          }}
          componentCn="flex gap-10 items-center"
          linkCn="no-underline uppercase text-main-text-color text-sm hover:translate-y-1"
        />
      </div>
    </header>
  );
}

export default HeaderKD;
